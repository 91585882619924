
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/translation'
import LogoDeltaLife from '~/components/Atom/Brand/LogoDeltaLife'

export default defineComponent({
  components: { LogoDeltaLife },
  props: {
    templateName: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('interactive-map'),
    }
  },
  data() {
    return {
      darkTheme: this.templateName === 'homePage',
    }
  },
})
