
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true, default: '' },
    titleClasses: { type: Array, required: false, default: () => [] },
    subtitle: { type: String, required: false, default: '' },
    subtitlePosition: { type: String, required: false, default: 'top' },
    url: { type: String, required: true, default: '' },
    textColor: { type: [String, Object], required: false, default: 'text-theme-secondary' },
  },
})
