import { render, staticRenderFns } from "./PageHeaderFullSize.vue?vue&type=template&id=69650242&scoped=true"
import script from "./PageHeaderFullSize.vue?vue&type=script&lang=js"
export * from "./PageHeaderFullSize.vue?vue&type=script&lang=js"
import style0 from "./PageHeaderFullSize.vue?vue&type=style&index=0&id=69650242&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69650242",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TypographyHeading: require('/home/userapp/components/Atom/Typography/TypographyHeading.vue').default,Picture: require('/home/userapp/components/Molecule/Picture.vue').default})
