
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    introduction: { type: String, required: false, default: '' },
    image: { type: Object, required: false, default: undefined },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('page-header-full-size'),
    }
  },
  data() {
    return {
      headerInited: false,
    }
  },
  mounted() {
    this.headerInited = true
  },
})
