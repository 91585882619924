
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  props: {
    templateName: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: '' },
    button: { type: Object, required: false, default: undefined },
    entries: { type: Array, required: true, default: () => [] },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('card-related-minimal'),
    }
  },
  data() {
    return {
      darkTheme: this.templateName === 'homePage',
    }
  },
  methods: {
    isEven(n) {
      return n % 2 === 0
    },
    constructSubTitle(entry) {
      switch (entry.section) {
        case 'newsPage':
          return entry.postDate ? this.t_('news') + ' | ' + this.d_(entry.postDate, 'long-date--2-digit') : null
        case 'storyPage':
          // eslint-disable-next-line no-case-declarations
          const titleText = 'storyTypes' in entry && entry.storyTypes[0] !== undefined ? entry.storyTypes[0] : this.t_('story')
          return entry.postDate ? titleText + ' | ' + this.d_(entry.postDate, 'long-date--2-digit') : null
        case 'eventPage':
          return entry.date ? this.t_('event') + ' ' + this.d_(entry.date, 'long-date--2-digit') : null
        default:
          return null
      }
    },
  },
})
